
import bus from "@/utils/bus";
import { Edit, Close } from "@element-plus/icons";
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  onUnmounted,
  nextTick
} from "vue";

export default defineComponent({
  setup() {
    let compRef = ref(null);
    let drawerVisible = ref(false);
    let dataList: Ref = ref<Array<any>>([]);
    let dataRow: Ref<any> = ref(null);
    let submitting = ref(false);
    let size: Ref<any> = ref("50%");
    let currentComp: any = ref(null);

    onMounted(() => {
      bus.on("on-table-row-view", (data: any): void => {
        let name = data.dataRow.defaultFormModelName.replace(
          "Model",
          "View"
        ) as string;

        dataList.value = data.dataList;
        dataRow.value = data.dataRow;

        drawerVisible.value = true;
        currentComp.value = name;
        console.log("=====================================显示的视图是", name);

        nextTick(() => {
          let component = compRef.value as any;
          component.setData(dataList, dataRow);
          if (component.drawerSize) size.value = component.drawerSize;
        });
      });
    });

    onUnmounted(() => {
      drawerVisible.value = false;
      bus.off("on-table-row-view");
    });

    let clickClose = () => {
      drawerVisible.value = false;
    };

    let clickEdit = () => {
      console.log("编辑");
      drawerVisible.value = false;
      bus.emit("on-table-row-edit", { dataList: dataList.value, dataRow: dataRow.value });
    };

    return {
      size,
      compRef,
      submitting,
      drawerVisible,
      dataRow,
      dataList,
      currentComp,
      clickClose,
      clickEdit,
      icon: {
        close: Close,
        edit: Edit
      }
    };
  }
});
